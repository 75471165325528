import React, { useEffect, useState } from 'react'
import SuccessGif from './../assets/images/Animation - 1706521632571.gif';
import { Link, useNavigate } from 'react-router-dom';
import { PiArrowLeftBold } from "react-icons/pi";
import LOGO from './../assets/images/vyapar_logo.png';
import { ApisService } from '../app/core/apis.service';
import { dataDecrypt } from '../app/utils/encryption-helper';

export const SuccessOrder = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [buDetailsData, setBuDetailsData] = useState<any>([])
    const navigation = useNavigate();

    useEffect(() => {
        loadBuDetails();
    }, [])
    async function loadBuDetails(): Promise<void> {
        setLoading(true)
        try {
            const id = "908b88a1939383024c36570992489ee3be209a4f0a0d11da4068c8c956c8a41b18fecc3d084e6c0d12c4cfe532d50562";
            const res = await ApisService.getBuDetailsData('findById', id || '');
            const data: any = res.data.data;
            const buData = JSON.parse(dataDecrypt(data));
            setBuDetailsData(buData[0])
            setLoading(false)
        } catch (error) {
            console.error('Error in products:', error);
            setLoading(false)
        }
    }

    const onClickNewOrder = () => {
        const id = "908b88a1939383024c36570992489ee3be209a4f0a0d11da4068c8c956c8a41b670fb9f5ed0f0e0b5e46ed19d9938c71b84708463c854c487f550bd5bc1c987332375af6eb6394bc9c8345790352a664b29bc86f23fe565fc7dd78d2ac3629486d4c1e926a43f3a0facc30f3e038b460"
        navigation(`/order-form/${id}`)
    }

    return (
        <div className='align-items-center'>
            <div className='d-flex flex-column justify-content-center'>
                <div className='d-flex justify-content-between p-3 align-items-center' style={{ backgroundColor: "#274472", color: "white" }}>
                    <div></div>
                    <span style={{ lineHeight: "0", textAlign: "center" }}><h4 className='ms-5 ps-2'>{buDetailsData?.business_name}</h4></span>
                    <img src={LOGO} alt="" height={60} />
                </div>
                <div className='d-flex justify-content-center my-3'>
                    <img src={SuccessGif} alt="" className='' style={{ objectFit: "contain", maxWidth: "100%", height: 'auto' }} />
                </div>
                <div className='text-success fs-3 text-center'>Congrats! Your order has been successfully placed</div>
                <div className='col-6 col-sm-3 col-md-2 px-3 px-sm-2 mx-auto my-3'>
                    <button className="btn btn-primary primary-background rounded-pill py-2" onClick={onClickNewOrder}>New order</button>
                </div>
            </div>
        </div>
    )
}
