import React from 'react';
import logo from './logo.svg';
import './App.css';
import './../src/assets/css/custom.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Transaction } from './pages/transaction';
import { Invoice } from './pages/invoice'
import { Packing_Inspections } from './pages/Packing _ Inspections';
import { Delivery } from './pages/delivery';
import { Stock_Reconciliation } from './pages/stock_reconciliation';
import { Payment_Reconciliation } from './pages/payment_reconciliation';
import LoginOtp from './pages/LoginOtp';
import { Retail } from './pages/retail';
import APPSTORE_ICON from './assets/images/app-appstore.png';
import PLAYSTORE_ICON from './assets/images/app-playstore.png';
import { TransactionPacking } from './pages/transaction_packing';
import { TransactionDelivery } from './pages/transaction_delivery';
import { TransactionStock } from './pages/transaction_stock';
import { DeleteAccount } from './pages/delete-account';
import { OrderFormListPage } from './pages/order-form';
import { SuccessOrder } from './pages/success_order';

function App() {
  const currentYear = new Date().getFullYear();
  return (
    <div >
      <BrowserRouter>

        <Routes>
          {/* <Route path='/' element={< LoginOtp />}></Route> */}
          <Route path='/order/:id' element={<Transaction />}></Route>
          <Route path='/invoice/:id' element={<Invoice />}></Route>
          <Route path='/packing/:id' element={<Packing_Inspections />}></Route>
          <Route path='/delivery/:id' element={<Delivery />}></Route>
          <Route path='/stock/:id' element={<Stock_Reconciliation />}></Route>
          <Route path='/payment/:id' element={<Payment_Reconciliation />}></Route>
          <Route path='/retail/:id' element={<Retail />}></Route>
          <Route path='/packing-details/:id' element={<TransactionPacking />}></Route>
          <Route path='/delivery-details/:id' element={<TransactionDelivery />}></Route>
          <Route path='/stock-details/:id' element={<TransactionStock />}></Route>
          <Route path='/delete-me/:userdata' element={<DeleteAccount />}></Route>
          <Route path='/order-form/:id' element={<OrderFormListPage />}></Route>
          <Route path='/success-order' element={<SuccessOrder />}></Route>
        </Routes>
      </BrowserRouter>
      <footer style={{ backgroundColor: "#274472", color: "white", minHeight: 300 }} className='container-fluid mt-1 pt-4'>
        <div className="row">
          <div className="col-12 text-center">
            <p>Download Vyapar live app to increase efficiency and smooth operations of your business</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text-center my-4 text-lg-end">
            <a href="https://play.google.com/store/apps/details?id=com.mr.vyaparlive" target='_blank'>
              <img src={PLAYSTORE_ICON} alt="" />
            </a>
          </div>
          <div className="col-md-6 text-center my-4 text-lg-start">
            {/* https://apps.apple.com/app/my-awesome-app/id123456789 */}
            <a href="https://apps.apple.com/app/com.mr.vyaparlive" target='_blank'>
              <img src={APPSTORE_ICON} alt="" />
            </a>
          </div>
        </div>
        <div className="text-center">
          <p>©{currentYear} <a href="https://www.vyaparlive.com/" style={{ color: 'yellow', textDecoration: 'none' }}>Vyaparlive</a>. All Rights Reserved</p>
        </div>
      </footer>
    </div >
  );
}

export default App;
